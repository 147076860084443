

























import { computed, defineComponent, reactive } from "@vue/composition-api";
import { ParticipantGroup } from "@/types/participantGroup";
import useTemplate from "@/use/templates/template";
import { jsPDF } from "jspdf";
import { computedAsync } from "@vueuse/core";

export default defineComponent({
  props: {
    participant: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const { render } = useTemplate({ root });
    const model = reactive({
      group: {} as any,
      title: `Certyfikat: ${props.participant.firstName} ${props.participant.lastName}`,
    });

    const buttonDisabled = computed(() => {
      if (model.group && model.group.id && model.title) {
        return false;
      } else return true;
    });
    const groupsWithCertificate = computed(() => {
      if (props.participant) {
        if (
          props.participant.participantGroups &&
          props.participant.participantGroups.length
        ) {
          const existingCertificates = props.participant.participantGroups.filter(
            (el: ParticipantGroup) =>
              el.certificateTemplates && el.certificateTemplates.length > 0
          );
          return existingCertificates;
        } else return [];
      }
    });

    const renderImage = computedAsync(() => {
      const dimensions = model.group.certificateTemplates[0].format;
      const items = model.group.certificateTemplates[0].items;
      return render({ format: dimensions, items: items }, props.participant);
    });

    const generateCertificate = () => {
      const format = model.group.certificateTemplates[0].format;

      const density = 300;

      const w = format.width / density;
      const h = format.height / density;

      const orientation = w > h ? "landscape" : "portrait";
      const width = orientation === "landscape" ? h : w;
      const height = orientation === "portrait" ? h : w;

      const jobName = `Certyfikat: ${props.participant.firstName} ${props.participant.lastName}`;

      const doc = new jsPDF({
        orientation: orientation,
        unit: "in",
        format: [width, height],
      });
      const imageData = renderImage.value;
      doc.addImage(imageData, 0, 0, width, height, "", "FAST");
      doc.save(`${jobName}.pdf`);
    };
    return {
      model,
      buttonDisabled,
      groupsWithCertificate,
      generateCertificate,
      renderImage,
    };
  },
});
